const toggle = document.querySelector('.nav__toggle');
const menu = document.querySelector('.nav__menu');

const showMenu = () => {

	menu.classList.add('nav__menu--is-active');
	toggle.setAttribute('aria-expanded', 'true');
	
};

const hideMenu = () => {

	toggle.setAttribute('aria-expanded', 'false');
	menu.classList.remove('nav__menu--is-active');
	
};

toggle.addEventListener('click', () => {

	if (menu.classList.contains('nav__menu--is-active')) {

		hideMenu();

	} else {

		showMenu();

	}
	
});

menu.addEventListener('click', (e) => {

	if (e.target.closest('.nav__menu-link')) {

		hideMenu();
		
	}
	
});


const headerBar = document.querySelector('.header__bar');
const nav = document.querySelector('.nav');

const observer = new IntersectionObserver((entries, observer) => {

	entries.forEach((entry) => {
		nav.classList.toggle('nav--is-stuck', !entry.isIntersecting);
	});
	
}, {threshold: 0});

observer.observe(headerBar);

